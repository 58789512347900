<template>
    <BlogOverviewItem v-if="data && data.contentName && data.contentName === 'Artikelen'" :data="data" />
    <FaqItem v-if="data && data.contentName && data.contentName === 'FAQ'" :data="data" />
    <VideoCategoryOverviewItem v-if="data && data.contentName && data.contentName === 'Video categories'" :data="data" />
    <TeacherOverviewItem v-if="data && data.contentName && data.contentName === 'Coaches & trainers'" :data="data" />
    <JourneyOverviewItem v-if="data && data.contentName && data.contentName === 'Journeys'" :data="data" />
</template>

<script>

import { defineAsyncComponent } from "vue";

const BlogOverviewItem = defineAsyncComponent(() => import("@/components/BlogOverviewItem"));
const FaqItem = defineAsyncComponent(() => import("@/components/FaqItem"));
const VideoCategoryOverviewItem = defineAsyncComponent(() => import("@/components/VideoCategoryOverviewItem"));
const TeacherOverviewItem = defineAsyncComponent(() => import("@/components/TeacherOverviewItem"));
const JourneyOverviewItem = defineAsyncComponent(() => import("@/components/JourneyOverviewItem"));

export default {
    data() {
        return {

        };
    },
    components: {
        BlogOverviewItem,
        FaqItem,
        VideoCategoryOverviewItem,
        TeacherOverviewItem,
        JourneyOverviewItem
    },
    mounted() {
        //console.log(this.data);
        // console.log(this.contentType);
        //console.log(this.data);
    },
    props: ['data', 'contentType'],
    inject: ["isAuthenticated"],
    methods: {

    }

};
</script>
